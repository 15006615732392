import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Motion, spring, presets } from "react-motion";
import { useSwipeable, config } from "react-swipeable";
import UnableToLoadData from "../Components/UnableToLoadData";
import ImageTag from "../Components/ImageTag";
import ImageViewer from "../Components/ImageViewer";
import ImageSlider from "../Components/ImageSlider";
import {
  addItemCartDetail,
  updateItemCartDetail,
  removeItemCartDetail,
  getTableDetailByFilter,
  emptyEditedItemDetail,
  showNotificationWithTimeout,
} from "../../Redux/actions";
import {
  badge,
  close_black,
  cross_icon,
  downDown,
  downUp,
  fireFlame,
  loyaltyHeart,
  minus,
  plus,
  shoppingcart,
  squreImgLgPngIcon,
  tags,
  trending,
} from "../../Assets";
import {
  getModifierActionName,
  getModifierTypeName,
  getModifierActionSign,
  checkNegNum,
  makeNegToPosNum,
  checkItemExistInCart,
  groupByArray,
  getRandomString,
  imageExists,
  getUniTimeString,
  checkExpiredSession,
  checkInactiveSession,
  checkArray,
  checkNull,
  checkIsNum,
} from "../../helpers";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { StyledSVG } from "../../Utils/style";
import SearchableSelect from "../Components/SearchableSelect";
import CustomDropDown from "../../Component/molecules/CustomDropDown";
import CustomButton from "../Components/CustomButton";
import CartDropDown from "../../Component/molecules/CartDropDown";

function AddItem({
  loading,
  selected_item_detail,
  edited_item_detail,
  table_detail,
  filters,
  filter_tag_detail,
  cart_detail,
  show_complete_page,
  addItemCartDetail,
  updateItemCartDetail,
  getTableDetailByFilter,
  emptyEditedItemDetail,
  removeItemCartDetail,
  recent_order_detail,
  show_session_expired_page,
  pos_cart_detail,
  showNotificationWithTimeout,
  is_payment_cleared,
  server_detail,
  server_user_detail,

  changeModalStyle = () => {},
  showAddItem,
}) {
  const {eber_details}=useSelector((state)=>state.authDetailReducer)
  console.log(selected_item_detail);
  const history = useHistory();
  const timezone = table_detail?.Merchant?.timezone_offset ?? "00:00";
  const currDateTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("yyyy/MM/DD HH:mm:ss");
  const showPostTaxPrice =
    table_detail?.Merchant?.MerchantFinanceInfo?.menu_pricing_default == "2"
      ? true
      : false;
  const isContainDiscountArr =
    selected_item_detail?.PosMenuitemsModifier &&
    selected_item_detail?.PosMenuitemsModifier.findIndex(
      (p) => p.action == "discount"
    ) > -1
      ? true
      : false;
  const serviceChargePer = parseFloat(table_detail?.ServiceCharges ?? 0);
  const salesTaxPer = parseFloat(table_detail?.SalesTaxRate ?? 0);
  const showNoteCon =
    table_detail?.QrOrdering?.hide_note !== "yes" ||
    server_detail?.CurrentRoom?.merchant_id
      ? true
      : false;

  const [wrapperClass, setWrapperClass] = useState("");
  const cartItemKey =
    cart_detail && cart_detail.length > 0 && selected_item_detail?.id
      ? cart_detail.findIndex((p) => p.id == selected_item_detail.id)
      : -1;
  const editedItemSelectedKey = edited_item_detail?.modifierArray?.findIndex(
    (p) => p.action == "discount"
  );
  const defaultSelectedItemPrice =
    editedItemSelectedKey !== undefined && editedItemSelectedKey > -1
      ? parseFloat(
          edited_item_detail?.modifierArray?.[editedItemSelectedKey]
            ?.modifier_price
        )
      : parseFloat(
          selected_item_detail?.dinein_price?.DineIn
            ? selected_item_detail?.dinein_price?.DineIn
            : 0
        );
  const [currentItemQytEdit, setCurrentItemQytEdit] = useState(false);
  const [currentItemQyt, setCurrentItemQyt] = useState(
    edited_item_detail?.quantity != undefined ||
      edited_item_detail?.quantity > 0
      ? edited_item_detail?.quantity
      : 1
  );
  const currentItemQytRef = useRef(currentItemQyt);
  currentItemQytRef.current = currentItemQyt;


  
  let isCodeMatch =true
      

  const loyaltyPromotion= table_detail?.Promotions?.filter((p)=>+p.loyalty_only_promotion)
  const nonLoyaltyPromotion=table_detail?.Promotions?.filter((p)=>!+p.loyalty_only_promotion)
 
  

 
  for(let i=0;i<loyaltyPromotion.length;i++){
    console.log("=========",selected_item_detail,loyaltyPromotion[i])
    const isExist=loyaltyPromotion[i].MenuItem.find((i)=>i.id===selected_item_detail.id)
    if(isExist &&loyaltyPromotion[i].loyalty_only_promotion){
      isCodeMatch=
eber_details?.redeemable_list?.find(
  (r) => r?.pos_redeem_sku === loyaltyPromotion[i]?.code
);
if(isCodeMatch)
     break;
console.log(selected_item_detail,isCodeMatch,eber_details,loyaltyPromotion[i])
}
   }




  // loyaltyPromotion.map((promotion)=>{
  //       const isExist=promotion.MenuItem.find((i)=>i.id===selected_item_detail.id)
  //       if(isExist &&promotion.loyalty_only_promotion)
  //             isCodeMatch=eber_details  &&
  //       eber_details?.redeemable_list?.find(
  //         (r) => r?.pos_redeem_sku === promotion?.code
  //       );
  // })

  console.log(loyaltyPromotion,nonLoyaltyPromotion,selected_item_detail)
  
  if(!isCodeMatch){
    nonLoyaltyPromotion.map((promotion)=>{
      const isExist=promotion.MenuItem.find((i)=>i.id===selected_item_detail.id)
      if(isExist)
        isCodeMatch=true
    })  
  }

  const isPromotion = selected_item_detail?.promotions?.id && isCodeMatch ? true : false;


  const [selectedItemPrice, setSelectedItemPrice] = useState(
    defaultSelectedItemPrice
  );
  const [totalPrice, setTotalPrice] = useState(parseFloat(selectedItemPrice));
  const [totalPriceWithTax, setTotalPriceWithTax] = useState(
    parseFloat(selectedItemPrice)
  );
  const [hideHeader, setHideHeader] = useState(false);
  const [hideImages, setHideImages] = useState(false);
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);
  const [showImageView, setShowImageView] = useState(false);
  const [modifierChanged, setModifierChanged] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [modifierArray, setModifierArray] = useState([]);
  const [imageArr, setImageArr] = useState([]);

  const imageArrRef = useRef(imageArr);
  imageArrRef.current = imageArr;

  const qytEditInputRef = useRef(null);

  useEffect(() => {
    if (currentItemQytEdit) {
      qytEditInputRef?.current?.focus();
    }
  }, [currentItemQytEdit]);

  useEffect(() => {
    if (show_complete_page) {
      history.push("/completed");
    }
  }, [show_complete_page]);

  const showBackWhite =
    (selected_item_detail?.StandardModifier &&
      selected_item_detail?.StandardModifier.length > 0) ||
    (selected_item_detail?.PosMenuitemsModifier &&
      selected_item_detail?.PosMenuitemsModifier.length > 0) ||
    (selected_item_detail?.StandardModifier &&
      selected_item_detail?.StandardModifier.length > 0)
      ? false
      : true;

  const getPostSaleTaxPrice = (val) => {
    let res = parseFloat(checkIsNum(val, 0));
    if (selected_item_detail?.dinein_price?.sales_tax == true) {
      const resTax = (res * salesTaxPer) / 100;
      res = res + resTax;
    }
    return res;
  };

  const getPreModifiers = () => {
    let res = [];
    if (
      edited_item_detail?.modifierArray != null ||
      edited_item_detail?.modifierArray?.length > 0
    ) {
      res = edited_item_detail?.modifierArray;
    } else if (
      selected_item_detail?.PosMenuitemsModifier &&
      selected_item_detail?.PosMenuitemsModifier?.length > 0 &&
      selected_item_detail?.recomItem?.modifier_ids &&
      selected_item_detail?.recomItem?.modifier_ids?.length > 0
    ) {
      selected_item_detail?.recomItem?.modifier_ids?.map((modItemId) => {
        const modIndex = selected_item_detail?.PosMenuitemsModifier?.findIndex(
          (p) => p.id == modItemId
        );
        if (modIndex > -1) {
          const modItem =
            selected_item_detail?.PosMenuitemsModifier?.[modIndex];
          const modifierType = modItem?.type ?? "";
          const modifierAction = modItem?.action ?? "";
          const modifierName = getModifierTypeName(modifierType, modItem);
          const unitPrice =
            modItem?.unit_price &&
            modItem?.unit_price !== null &&
            modItem?.unit_price !== ""
              ? parseFloat(modItem?.unit_price)
              : 0;
          res.push({
            id: modItem?.id,
            modifier_id: modItem?.id,
            modifier_group_id: modItem?.modifier_group_id,
            action: modifierAction,
            name: modifierName,
            unit_price: parseFloat(unitPrice),
            modifier_price: parseFloat(unitPrice),
            modifier_quantity: 1,
          });
        }
      });
    }
    if (res.length > 0) {
      setModifierArray(res);
      setModifierChanged(!modifierChanged);
    }
    return res;
  };

  function getImages(images) {
    let arr = images?.map(async (img) => {
      if (img?.file_name) {
        let a = await imageExists(img?.file_name);
        return a;
      }
    });
    Promise.all(arr)
      .then((resp) => {
        if (resp && resp?.length > 0) {
          setImageArr(resp);
        } else {
          setHideImages(true);
          setHideHeader(true);
          setWrapperClass("scroll-active");
        }
      })
      .catch((er) => {
        setHideImages(true);
        setHideHeader(true);
        setWrapperClass("scroll-active");
      });
  }

  const [comment, setComment] = useState(edited_item_detail?.comment ?? "");
  // console.log("modifierArray", modifierArray);
  // console.log("imageArr>>>>>", imageArr);
  // console.log("selectedOption", selectedOption);

  function calTotalPrice() {
    const currentQyt = currentItemQytRef.current;
    const modifierAddPrice =
      modifierArray.length > 0
        ? modifierArray
            .filter((item) => item.action == "add" || item.action == "swap")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const modifierRemovePrice =
      modifierArray.length > 0
        ? modifierArray
            .filter((item) => item.action == "remove")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const modifierSizePrice =
      modifierArray.length > 0
        ? modifierArray
            .filter((item) => item.action == "size")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const priceWithModifier =
      selectedItemPrice * currentQyt +
      modifierAddPrice * currentQyt -
      modifierRemovePrice * currentQyt +
      modifierSizePrice * currentQyt;
    setTotalPrice(parseFloat(priceWithModifier.toFixed(2)));
    setTotalPriceWithTax(getPostSaleTaxPrice(priceWithModifier));
  }

  const calculateIsToolBarStatus = () => {
    let res = false;
    let maxHeight = window.outerHeight - window.innerHeight;
    if (maxHeight > 120) {
      res = true;
    }
    return res;
  };

  const [totalHeight, setTotalHeight] = useState(window.innerHeight);
  const totalHeightRef = useRef(totalHeight);
  totalHeightRef.current = totalHeight;
  const [isToolBarHide, setIsToolBarHide] = useState(
    calculateIsToolBarStatus()
  );

  useEffect(() => {
    setIsToolBarHide(calculateIsToolBarStatus());
  }, [totalHeight]);

  useEffect(() => {
    getImages(
      selected_item_detail?.content?.length > 0
        ? selected_item_detail?.content
        : selected_item_detail?.image
        ? [{ file_name: selected_item_detail?.image }]
        : []
    );
    getPreModifiers();

    /* check expired session */
    const checkExpired = checkExpiredSession(table_detail);
    const checkInactive =
      recent_order_detail === null && !server_detail
        ? checkInactiveSession()
        : false;
    if (checkExpired || checkInactive) {
      if (server_detail?.CurrentRoom) {
        history.push(
          `/get_server_details/${server_detail.CurrentRoom.merchant_id}/${server_detail.CurrentRoom.server_code}`
        );
      } else {
        history.push("/session-expired");
      }
    }
    /* check expired session END */

    window.addEventListener("resize", function (event) {
      setTotalHeight(window.innerHeight);
    });

    return () => {
      emptyEditedItemDetail();
      window.removeEventListener("resize", (e) => {});
    };
  }, []);

  useEffect(() => {
    if (show_session_expired_page) {
      if (server_detail?.CurrentRoom) {
        history.push(
          `/get_server_details/${server_detail.CurrentRoom.merchant_id}/${server_detail.CurrentRoom.server_code}`
        );
      } else {
        history.push("/session-expired");
      }
    }
  }, [show_session_expired_page]);

  useEffect(() => {
    calTotalPrice();
  }, [selectedItemPrice, modifierChanged]);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("User Swiped!", eventData.dir);
      if (
        hideHeader == true &&
        eventData.dir == "Down" &&
        hideImages == false &&
        isHeaderAllUp === true
      ) {
        setWrapperClass("");
        setHideHeader(false);
      }
      if (hideHeader == false && eventData.dir == "Up") {
        setWrapperClass("scroll-active");
        setHideHeader(true);
      }
    },
    ...config,
  });

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;
    if (headerPageOffset === 0 && hideHeader === true) {
      setIsHeaderAllUp(true);
    }
    if (headerPageOffset > 0 && isHeaderAllUp === true) {
      setIsHeaderAllUp(false);
    }
  }

  const editItemQyt = (quantity) => {
    setCurrentItemQyt(quantity);
    setModifierChanged(!modifierChanged);
  };

  const increaseItem = () => {
    const currentQyt = currentItemQyt + 1;
    setCurrentItemQyt(currentQyt);
    setModifierChanged(!modifierChanged);
  };

  const decreaseItem = () => {
    const currentQyt = currentItemQyt > 1 ? currentItemQyt - 1 : currentItemQyt;
    setCurrentItemQyt(currentQyt);
    setModifierChanged(!modifierChanged);
  };

  function getItemModifiersItems(modifers, action) {
    let res = null;
    if (modifers?.length > 0) {
      res = modifers
        .sort(function (a, b) {
          return parseInt(a?.is_sort ?? 0) - parseInt(b?.is_sort ?? 0);
        })
        .filter(
          (p) =>
            p.action == "size" ||
            p.action == "add" ||
            p.action == "remove" ||
            p.action == "swap"
        )
        // .filter((p) =>
        //   action === "size"
        //     ? p.action == "size"
        //     : p.action == "add" || p.action == "remove" || p.action == "swap"
        // )
        .map((element, index) => {
          const modifierType = element?.type ?? "";
          const modifierAction = element?.action ?? "";
          if (
            modifierType == "ingredient" ||
            modifierType == "recipe" ||
            modifierAction == "add" ||
            modifierAction == "remove" ||
            modifierAction == "swap" ||
            modifierAction == "size"
          ) {
            const modifierName = getModifierTypeName(modifierType, element);
            const elUnitPrice = showPostTaxPrice
              ? getPostSaleTaxPrice(element?.unit_price)
              : element?.unit_price;
            return (
              <div className="options" key={index}>
                <input
                  id={`modifier_${element?.id}`}
                  type="checkbox"
                  className="check"
                  checked={
                    modifierArray.findIndex((p) => p.id == element?.id) > -1
                  }
                  onChange={(el) => {
                    if (el?.target?.checked) {
                      const unitPrice =
                        element?.unit_price &&
                        element?.unit_price !== null &&
                        element?.unit_price !== ""
                          ? parseFloat(element?.unit_price)
                          : 0;

                      const elArr = {
                        id: element?.id,
                        modifier_id: element?.id,
                        modifier_group_id: element?.modifier_group_id,
                        action: modifierAction,
                        name: modifierName,
                        unit_price: parseFloat(unitPrice),
                        modifier_price: parseFloat(unitPrice),
                        modifier_quantity: 1,
                      };
                      const existIndex = modifierArray.findIndex(
                        (p) => p.action == modifierAction
                      );
                      if (existIndex > -1 && modifierAction == "size") {
                        setModifierArray([
                          ...modifierArray.slice(0, existIndex),
                          elArr,
                          ...modifierArray.slice(existIndex + 1),
                        ]);
                      } else {
                        setModifierArray([...modifierArray, elArr]);
                      }
                      setModifierChanged(!modifierChanged);
                    } else if (
                      modifierArray.findIndex((p) => p.id == element?.id) > -1
                    ) {
                      setModifierArray(
                        modifierArray.filter(
                          (modifier) => modifier.id != element?.id
                        )
                      );
                      setModifierChanged(!modifierChanged);
                    }
                  }}
                />
                <label htmlFor={`modifier_${element?.id}`}>
                  {" "}
                  {/* {getModifierActionName(modifierAction)}{" "} */}
                  {modifierAction === "remove" ? "" : ""}
                  {getModifierTypeName(modifierType, element)}
                </label>

                {elUnitPrice !== null &&
                  elUnitPrice !== "" &&
                  (elUnitPrice > 0 || element?.action == "size") && (
                    <span className="price-sm price-position">
                      {" "}
                      {/* size action todo */}
                      {getModifierActionSign(modifierAction)}{" "}
                      {checkNegNum(elUnitPrice) ? "- " : ""}
                      {`${table_detail?.currency ?? ""}`}
                      {parseFloat(
                        checkNegNum(elUnitPrice)
                          ? makeNegToPosNum(elUnitPrice)
                          : elUnitPrice
                      ).toFixed(2)}
                    </span>
                  )}
              </div>
            );
          }
        });
    }
    return res;
  }

  function getItemModifiers(action = "") {
    let res = null;
    if (
      selected_item_detail?.PosMenuitemsModifier &&
      selected_item_detail?.PosMenuitemsModifier.length > 0
    ) {
      const groupedModifiers = groupByArray(
        selected_item_detail?.PosMenuitemsModifier.filter(
          (p) =>
            p.action == "size" ||
            p.action == "add" ||
            p.action == "remove" ||
            p.action == "swap"
        ),
        // selected_item_detail?.PosMenuitemsModifier.filter((p) =>
        //   action === "size"
        //     ? p.action == "size"
        //     : p.action == "add" || p.action == "remove" || p.action == "swap"
        // ),
        "modifier_group_id"
      );
      const groupedModifiersArr = Object.values(groupedModifiers);
      // console.log("groupedModifiersArr", groupedModifiersArr);
      if (groupedModifiersArr) {
        res = groupedModifiersArr.map((group) => {
          if (group.length > 0)
            return (
              <>
                <h5 className="sm-title  bg-white Px-15 tt-p">
                  {group?.[0]?.["modifier_group_name"]}
                </h5>

                <div className="selectors Px-15 py-0 ">
                  {getItemModifiersItems(group, action)}
                </div>
              </>
            );
        });
      }
    }
    return res;
  }

  const checkExtraSpace = () => {
    // let res = false;
    if (
      (selected_item_detail?.PosMenuitemsModifier &&
        selected_item_detail?.PosMenuitemsModifier.length > 0) ||
      (selected_item_detail?.StandardModifier &&
        selected_item_detail?.StandardModifier.length)
    ) {
      console.log("checkExtraSpace----------------->>>>>>>>>>>>>>1");
      return true;
    } else if (isContainDiscountArr) {
      console.log("checkExtraSpace----------------->>>>>>>>>>>>>>2");
      return true;
    }
    // else if(selected_item_detail?.allergy_tags &&
    //   selected_item_detail?.allergy_tags.length)
    //   {console.log("checkExtraSpace----------------->>>>>>>>>>>>>>3",selected_item_detail)
    //     return true;
    //   }
    return false;
  };

  function getItemDiscountModifiers() {
    let res = null;
    if (
      selected_item_detail?.PosMenuitemsModifier &&
      selected_item_detail?.PosMenuitemsModifier.length > 0
    ) {
      const discountModifiersArr =
        selected_item_detail?.PosMenuitemsModifier.filter(
          (p) => p.action == "discount"
        ).map((element, index) => {
          const modifierType = element?.type ?? "";
          const modifierAction = element?.action ?? "";
          const modifierName = getModifierTypeName(modifierType, element);
          const unitPrice =
            element?.unit_price &&
            element?.unit_price !== null &&
            element?.unit_price !== ""
              ? parseFloat(element?.unit_price)
              : 0;
          return (
            <li className="position-relative">
              <input
                type="radio"
                name="select_options"
                id={`modifier_${element?.id}`}
                className="check"
                onClick={() => {
                  const discountEl = {
                    id: element?.id,
                    modifier_id: element?.id,
                    modifier_group_id: element?.modifier_group_id,
                    action: modifierAction,
                    name: modifierName,
                    unit_price: parseFloat(unitPrice),
                    modifier_price: parseFloat(unitPrice),
                    modifier_quantity: element?.amount_per_item,
                  };
                  const existIndex = modifierArray.findIndex(
                    (p) => p.action == "discount"
                  );
                  if (existIndex > -1) {
                    setModifierArray([
                      ...modifierArray.slice(0, existIndex),
                      discountEl,
                      ...modifierArray.slice(existIndex + 1),
                    ]);
                  } else {
                    setModifierArray([...modifierArray, discountEl]);
                  }
                  setSelectedItemPrice(parseFloat(unitPrice));
                }}
                checked={
                  modifierArray.findIndex((p) => p.id == element?.id) > -1
                }
              />
              <label htmlFor={`modifier_${element?.id}`}>
                {element?.amount_per_item} x{" "}
                {selected_item_detail?.menu_item_name}
              </label>
              <span className="price-sm price-position">
                {`${table_detail?.currency ?? ""}`}
                {parseFloat(unitPrice).toFixed(2)}
              </span>
            </li>
          );
        });
      res = (
        <div className="options">
          <ul className="w-100">
            <li className="mb-4 position-relative">
              <input
                type="radio"
                name="select_options"
                id={`default_options`}
                className="check"
                onClick={() => {
                  setModifierArray(
                    modifierArray.filter(
                      (modifier) => modifier.action != "discount"
                    )
                  );
                  setSelectedItemPrice(defaultSelectedItemPrice);
                }}
                checked={
                  modifierArray.findIndex((p) => p.action == "discount") < 0
                }
              />
              <label htmlFor="default_options">
                1 x {selected_item_detail?.menu_item_name}
              </label>
              <span className="price-sm price-position">
                {`${table_detail?.currency ?? ""}`}
                {parseFloat(defaultSelectedItemPrice).toFixed(2)}
              </span>
            </li>
            {discountModifiersArr}
          </ul>
        </div>
      );
    }
    return res;
  }

  function getItemStandardModifiers() {
    let res = null;
    console.log(selected_item_detail);
    if (
      selected_item_detail?.StandardModifier &&
      selected_item_detail?.StandardModifier.length > 0
    ) {
      res = selected_item_detail?.StandardModifier.map((stanEl, stanIndex) => {
        return (
          <>
            {/* <h5 className="sm-title bg-white pb-4 pt-5" key={stanIndex}>
              {`${stanEl?.ModifierClass?.name} [must be select 1 item]` ??
                "efdewf"}
            </h5> */}

            <h5 className="sm-title bg-white Px-15 tt-p">
              {" "}
              {stanEl?.ModifierClass?.name}{" "}
              <span className="mt-2 modifier-options">{`(Must Select 1)`}</span>{" "}
              {/* {group?.[0]?.["modifier_group_name"]} <span>must be selected</span> */}
            </h5>

            <div className="selectors Px-15 py-0">
              {stanEl?.ModifierClass?.Modifier?.map((element, index) => {
                const modifierId = element?.id ? `SM-${element?.id}` : "";
                const modifierType = element?.type ?? "";
                const modifierAction = stanEl?.ModifierClass?.name ?? "";
                const modifierName = element?.name ?? "";
                return (
                  <div className="options" key={index}>
                    <input
                      id={`standard_modifier_${modifierId}`}
                      type="checkbox"
                      className="check"
                      checked={
                        modifierArray.findIndex((p) => p.id == modifierId) > -1
                      }
                      onChange={(el) => {
                        if (el?.target?.checked) {
                          const addEl = {
                            id: modifierId,
                            modifier_id: modifierId,
                            action: modifierAction,
                            name: modifierName,
                            unit_price: 0,
                            modifier_price: 0,
                            modifier_quantity: 1,
                          };
                          const existIndex = modifierArray.findIndex(
                            (p) => p.action == modifierAction
                          );
                           
                        

                          if (existIndex > -1) {
                            setModifierArray([
                              ...modifierArray.slice(0, existIndex),
                              addEl,
                              ...modifierArray.slice(existIndex + 1),
                            ]);
                           
                          } else {
                            setModifierArray([...modifierArray, addEl]);
                          }
                          
                         
                        } else if (
                          modifierArray.findIndex((p) => p.id == modifierId) >
                            -1 
                        ) {
                            


                          setModifierArray(
                            modifierArray.filter(
                              (modifier) => modifier.id != modifierId
                            )
                          );

                         
                        }
                      }}
                    />
                    <label htmlFor={`standard_modifier_${modifierId}`}>
                      {" "}
                      {/* {getModifierActionName(modifierAction)}{" "} */}
                      {modifierName}
                    </label>
                  </div>
                );
              })}
            </div>
          </>
        );
      });
    }
    return res;
  }

  function getItemTags() {
    let res = null;
    // if(selected_item_detail?.is_popular && selected_item_detail?.is_popular === 1 &&selected_item_detail?.is_trending && selected_item_detail?.is_trending === 1)
    // {
    //   res = (
    //    <span className="d-flex align-items-center">
    //      <span className="d-flex align-items-center ml-3">
    //       <StyledSVG src={fireFlame} />
    //       <span className="item-tags ml-1">Popular</span>
    //     </span>
    //     <span className="d-flex align-items-center ml-3">
    //     <StyledSVG src={fireFlame} />
    //     <span className="item-tags ml-1">Trending</span>
    //   </span>
    //    </span>
    //   );
    // }
    // else
    if (
      selected_item_detail?.is_popular &&
      selected_item_detail?.is_popular === 1
    ) {
      res = (
        <span className=" ">
          <StyledSVG src={fireFlame} />
          <span className="item-tags ml-1">Popular</span>
        </span>
      );
    } else if (
      selected_item_detail?.is_trending &&
      selected_item_detail?.is_trending === 1
    ) {
      res = (
        <span className="">
          <StyledSVG src={trending} color={"#9A9A9A"} />
          <span className="item-tags ml-1">Trending</span>
        </span>
      );
    }
    return res;
  }

  function getItemFilterTags(item) {
    let res = null;
    if (item?.allergy_tags && item?.allergy_tags.length > 0) {
      res = (
        <span>
          {item?.allergy_tags.map(function (tag, index) {
            const filterTagKey =
              filter_tag_detail && filter_tag_detail.length > 0
                ? filter_tag_detail.findIndex((p) => p.id == tag)
                : null;
            const filterTag =
              filterTagKey !== null && filterTagKey !== undefined
                ? filter_tag_detail[filterTagKey]
                : null;
            const style =
              filterTag?.color && filterTag?.color !== ""
                ? { background: filterTag?.color ?? "" }
                : {};
            const className =
              filterTag?.color && filterTag?.color !== "" ? "" : "gray";
            const fullFilterName =
              filterTag?.name && filterTag?.name !== "" ? filterTag?.name : "";

            return (
              <span
                // style={style}
                className={`filterTag-menuItem`}
                key={index}
                data-tip={`${fullFilterName}`}
              >
                {/* {filterTag?.symbol ?? ""} */}
                {fullFilterName}
                {item?.allergy_tags.length - 1 != index ? ", " : ""}
              </span>
            );
          })}
        </span>
      );
    }
    return res;
  }

  const checkMustSelectedItems = () => {
    let res = { status: false, limit: 0, name: "" };
    console.log(modifierArray)
    

    const isElementInArray = (element, arr) => {
      return arr.some(item => item.action === element.ModifierClass?.name);
    }; 
    const missingElements = selected_item_detail?.StandardModifier?.filter(element => !isElementInArray(element, modifierArray));
    console.log(missingElements);
    // if (
    //   standardModifierArr.length !==
    //   selected_item_detail?.StandardModifier?.length
    // ) {
     

    //   const result = selected_item_detail?.StandardModifier?.map(
    //     (modifiers) =>{          
    //       let flag=false;
    //       standardModifierArr&&standardModifierArr?.map((data)=>{
    //          console.log(data.action===modifiers?.ModifierClass?.name)
    //         if(data.action===modifiers?.ModifierClass?.name){
    //           flag= true
    //           return 
    //         }
    //       })
    //       if(!flag) return modifiers?.ModifierClass?.name
    //     }
    //   );
    //   console.log(
    //     selected_item_detail.StandardModifier,
    //     standardModifierArr,
    //     result
    //   );
    //   return { status: true, limit: 1, name: result.find((data)=>data) };
    // }

      if(missingElements.length>0){
        res.status=true
         res.limit=1
         res.name=missingElements[0]?.ModifierClass?.name 
      }
    return res;
  };



  function AddToCartHandler() {
    console.log(
      "selected_item_detail ============================================= ",
      selected_item_detail
    );

    const checkMustSelectItems = checkMustSelectedItems();

    if (checkMustSelectItems?.status) {
      showNotificationWithTimeout(
        `Please select atleast 1 under ${checkMustSelectItems.name} section!`,
        "error"
      );
      return;
    }

    if (selected_item_detail?.id) {
      const modifierAddPrice =
        modifierArray.length > 0
          ? modifierArray
              .filter((item) => item.action == "add" || item.action == "swap")
              .map((item) => item.unit_price)
              .reduce((prev, curr) => prev + curr, 0)
          : 0;
      const modifierRemovePrice =
        modifierArray.length > 0
          ? modifierArray
              .filter((item) => item.action == "remove")
              .map((item) => item.unit_price)
              .reduce((prev, curr) => prev + curr, 0)
          : 0;
      const modifierSizePrice =
        modifierArray.length > 0
          ? modifierArray
              .filter((item) => item.action == "size")
              .map((item) => item.unit_price)
              .reduce((prev, curr) => prev + curr, 0)
          : 0;
      const modifersPrice =
        modifierAddPrice - modifierRemovePrice + modifierSizePrice;
      let actualItemPrice = selectedItemPrice;
      if (selected_item_detail?.promotions) {
        // total amount changes with percentage discount
        if (
          modifersPrice !== 0 &&
          selected_item_detail.promotions.type == "Percentage"
        ) {
          let discountPrice =
            ((selected_item_detail.promotions.accutalPrice + modifersPrice) *
              parseFloat(selected_item_detail.promotions.amount)) /
            100;
          actualItemPrice =
            selected_item_detail.promotions.accutalPrice - discountPrice >= 0
              ? selected_item_detail.promotions.accutalPrice - discountPrice
              : 0;
        }
      }
      const singlePriceWithModifier = actualItemPrice + modifersPrice;
      const priceWithModifier = singlePriceWithModifier * currentItemQyt;
      const serviceChargeAmount =
        selected_item_detail?.dinein_price?.service_charge == true
          ? parseFloat(
              (parseFloat(priceWithModifier ?? 0) * serviceChargePer) / 100
            )
          : 0;
      const salesTaxAmount =
        selected_item_detail?.dinein_price?.sales_tax == true
          ? parseFloat(
              ((priceWithModifier + serviceChargeAmount) * salesTaxPer) / 100
            )
          : 0;
      let doubleActualAmout = parseFloat(
        selected_item_detail?.promotions?.accutalPrice
          ? selected_item_detail?.promotions?.accutalPrice
          : selected_item_detail?.dinein_price?.DineIn ?? 0
      );
      let item = {
        id: selected_item_detail?.id,
        offline_id: `${getUniTimeString()}`,
        firetime: null,
        createDate: currDateTime,
        is_fired: false,
        is_hold: false,
        is_server_fired: selected_item_detail?.is_server_fired || "no",
        name: selected_item_detail?.pos_name,
        pos_name: selected_item_detail?.menu_item_name,
        kitchen_docket_language: selected_item_detail?.kitchen_docket_language,
        chinese_menu_item_name: selected_item_detail?.chinese_menu_item_name,
        image: selected_item_detail?.image,
        default_course: selected_item_detail?.default_course,
        menu_class_name: selected_item_detail?.menu_class_name ?? "",
        is_added: false,
        void: 0,
        discount: 0,
        discountType: "",
        doubleActualAmout: doubleActualAmout,
        is_service_charge:
          selected_item_detail?.dinein_price?.service_charge ?? false,
        is_sales_tax: selected_item_detail?.dinein_price?.sales_tax ?? false,
        quantity: currentItemQyt,
        amount: selected_item_detail?.promotions?.accutalPrice
          ? selected_item_detail?.promotions?.accutalPrice + modifersPrice
          : singlePriceWithModifier,
        amount_without_modifiers: parseFloat(actualItemPrice),
        item_amount: actualItemPrice,
        actual_menu_price: parseFloat(
          selected_item_detail?.promotions?.accutalPrice
            ? selected_item_detail?.promotions?.accutalPrice
            : selected_item_detail?.dinein_price?.DineIn ?? 0
        ),
        comment: comment,
        total: priceWithModifier,
        menu_item_type: selected_item_detail?.menu_item_type ?? "",
        menu_price: selected_item_detail?.menu_price ?? "",
        service_charge_amount: serviceChargeAmount,
        sales_tax_amount: salesTaxAmount,
        catID: selected_item_detail?.other_category_id ?? "",
        sub_class_id: selected_item_detail?.sub_class_id ?? "",
        sub_category_id: selected_item_detail?.sub_category_id ?? "",
        modifierArray: modifierArray.sort(function (a, b) {
          return a.id - b.id;
        }),
      };
      if (selected_item_detail?.promotions) {
        item.promotions = selected_item_detail.promotions;
        if (modifersPrice !== 0 && item.promotions.type == "Percentage") {
          let discountPrice =
            ((item.promotions.accutalPrice + modifersPrice) *
              parseFloat(item.promotions.amount)) /
            100;
          item.promotions.promotionTotaldiscount =
            parseFloat(discountPrice) * currentItemQyt;
          item.promotions.discountPerItem = discountPrice;
        }
        if (item.promotions.accutalPrice < item.promotions.discountPerItem) {
          item.promotions.discountPerItem = item.promotions.accutalPrice;
          item.promotions.promotionTotaldiscount =
            parseFloat(item.promotions.acutalPrice) * currentItemQyt;
        }
      }
      const checkItemExistInCartKey = checkItemExistInCart(item, cart_detail);
      if (
        (edited_item_detail !== null &&
          edited_item_detail?.offline_id !== undefined) ||
        checkItemExistInCartKey > -1
      ) {
        const existOffId =
          checkItemExistInCartKey > -1
            ? checkItemExistInCartKey
            : cart_detail?.findIndex(
                (p) => p.offline_id == edited_item_detail.offline_id
              );
        if (existOffId > -1) {
          updateItemCartDetail(existOffId, item);
        } else addItemCartDetail(item);
      } else {
        addItemCartDetail(item);
      }
      console.log("items sleclected final block");
      getTableDetailByFilter(
        table_detail?.PosTableMaster?.qr_code_id,
        filters,
        ""
      );

      if (!showAddItem) {
        server_detail
          ? history.push(
              `/get_table_details/server/${table_detail?.PosTableMaster?.qr_code_id}`
            )
          : history.push(
              `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
            );
      } else {
        changeModalStyle();
      }

      // server_detail
      //   ? history.push(
      //       `/get_table_details/server/${table_detail?.PosTableMaster?.qr_code_id}`
      //     )
      //   : history.push(
      //       `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
      //     );
      // history.push(
      //   `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
      // );
    }
    return;
  }

  const cal_discount = () => {
    const orginal = isPromotion
      ? showPostTaxPrice
        ? selected_item_detail?.promotions?.accutalPostTaxPrice
          ? parseFloat(
              selected_item_detail?.promotions?.accutalPostTaxPrice
            ).toFixed(2)
          : "0.00"
        : selected_item_detail?.promotions?.accutalPrice
        ? parseFloat(selected_item_detail?.promotions?.accutalPrice).toFixed(2)
        : "0.00"
      : showPostTaxPrice
      ? selected_item_detail?.dinein_price?.DineInPostTax
        ? parseFloat(selected_item_detail?.dinein_price?.DineInPostTax).toFixed(
            2
          )
        : "0.00"
      : selected_item_detail?.dinein_price?.DineIn
      ? parseFloat(selected_item_detail?.dinein_price?.DineIn).toFixed(2)
      : "0.00";

    const new_price = showPostTaxPrice
      ? selected_item_detail?.dinein_price?.DineInPostTax
        ? parseFloat(selected_item_detail?.dinein_price?.DineInPostTax).toFixed(
            2
          )
        : "0.00"
      : selected_item_detail?.dinein_price?.DineIn
      ? parseFloat(selected_item_detail?.dinein_price?.DineIn).toFixed(2)
      : "0.00";
    console.log("new_price/orginal;", new_price, orginal);
    console.log("new_price/orginal;", orginal - new_price / orginal);
    console.log("new_price/orginal;", orginal - new_price);
    const price_diff = orginal - new_price;
    console.log("new_price/orginal;", price_diff / orginal);

    return price_diff / orginal;
  };

  const [originalHeight, setOriginalHeight] = useState(0);

  useEffect(() => {
    console.log(
      "inside the Scroll useEffect of add-items ==================================="
    );
    const headerImg = document.getElementById("header-img");
    const header = document.getElementById("header");

    if (header && headerImg) {
      // Set initial height of the image
      setOriginalHeight(header.clientHeight);

      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        if (headerImg) {
          headerImg.style.transform = `scale(${
            1 + scrollPosition / originalHeight
          })`;
        }

        // Reset image size when scrolling back to the top
        if (scrollPosition === 0 && headerImg) {
          headerImg.style.transform = "scale(1)";
        }
      };

      // Add scroll event listener
      window.addEventListener("scroll", handleScroll);

      return () => {
        // Clean up event listener on unmount
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [originalHeight]);
  useEffect(() => {
    const handleScroll = () => {
      console.log("window.scrollY--------------inside the add-items Screen ");
      // if (window.scrollY > 100) {
      //   // Change 100 to your desired scroll position
      //   setHideHeader(true);
      // } else {
      //   setHideHeader(false);
      // }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [isOpen, seIsOpen] = useState(false);
  console.log(
    "Reached till  end --------------------------------- >>>>>>>>>>>>>>. "
  );
  function getAddItemDetail() {
    if (table_detail?.PosTableMaster && table_detail?.Merchant) {
      return (
        <div>
          {/* <div className="popup-content"> */}
          <header className="add-item-header  sticky-top bg-white p-3 Px-15">
            <h1 className="add-items-title">Basket Summary</h1>
            <div
              class={`dropdown w-100 ${
                isOpen ? "dropdown-cart  open " : "dropdown-car"
              } dropdown-cart dropdown-add `}
            >
              <button
                onClick={() => seIsOpen(!isOpen)}
                class="my-btn btn p-2 px-4 d-flex w-100 justify-content-between align-items-center dropdown-cart  "
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                disabled={
                  cart_detail.filter(
                    (p) =>
                      p?.is_added == false ||
                      (p.is_fired == false && p.is_fired == true)
                  ).length == 0
                }
              >
                {cart_detail
                  .filter(
                    (p) =>
                      p?.is_added == false ||
                      (p.is_fired == false && p.is_fired == true)
                  )
                  .reduce((sum, item) => sum + item.quantity, 0) +
                  "  Items in basket"}
                <StyledSVG src={downDown} className="collapse-content" />
              </button>

              <ul
                className={`dropdown-menu ${
                  isOpen ? "show" : null
                } animated-dropdown dropdown-menu-cart text-start px-2`}
              >
                {cart_detail
                  .filter(
                    (p) =>
                      p?.is_added == false ||
                      (p.is_fired == false && p.is_fired == true)
                  )
                  .map((item, index) => (
                    <li>
                      <a
                        class={
                          index != cart_detail.length - 2
                            ? "dropdown-item p-4"
                            : "dropdown-item p-4 border-0"
                        }
                      >
                        {item?.quantity + " x " + item?.name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </header>
          {/* <div id="header">
            {imageArr.length ? (
              <div className="img-section position-relative">
                  <img
                  id="header-img"
                  className="img-fluid-add d-block mx-auto"
                  src={imageArr[0]}
                  alt="Header Image"
                />
               
                <div
                  onClick={() =>{
                    if(!showAddItem)
                    {
                       server_detail
                      ? history.push(
                          `/get_table_details/server/${table_detail?.PosTableMaster?.qr_code_id}`
                        )
                      : history.push(
                          `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
                        )

                    }
                    else{
                    changeModalStyle()
                    }
                  }
                    // server_detail
                    //   ? history.push(
                    //       `/get_table_details/server/${table_detail?.PosTableMaster?.qr_code_id}`
                    //     )
                    //   : history.push(
                    //       `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
                    //     )
                  }
                  className="cross-btn"
                  data-bs-dismiss="modal"
                >
                
                </div>
                
              </div>
            ) : null}
          </div> */}

          <section className="add-items_sec custom_radio default-bg ">
            <div id="header">
              {imageArr.length ? (
                <div className="img-section position-relative">
                  <img
                    id="header-img"
                    className="img-fluid-add d-block mx-auto"
                    src={imageArr[0]}
                    alt="Header Image"
                  />

                  <div
                    onClick={
                      () => {
                        if (!showAddItem) {
                          server_detail
                            ? history.push(
                                `/get_table_details/server/${table_detail?.PosTableMaster?.qr_code_id}`
                              )
                            : history.push(
                                `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
                              );
                        } else {
                          changeModalStyle();
                        }
                      }
                      // server_detail
                      //   ? history.push(
                      //       `/get_table_details/server/${table_detail?.PosTableMaster?.qr_code_id}`
                      //     )
                      //   : history.push(
                      //       `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
                      //     )
                    }
                    className="cross-btn"
                    data-bs-dismiss="modal"
                  ></div>
                </div>
              ) : null}
            </div>
            {/* <div
              className={`foobar-header row mx-0 ${
                hideHeader === true ? "touch-top" : "hide-top"
              } Px-15`}
              style={{
                justifyContent: "space-between",
              }}
            >
              <div className="d-flex  justify-content-between pl-0 ">
                <div className="d-flex align-items-center">
                  <h5 className="item-title mr-2">
                    {" "}
                    {selected_item_detail?.menu_item_name}{" "}
                  </h5>
                  {getItemTags()}
                </div>
                <StyledSVG
                  onClick={() =>
                    {
                      if(!showAddItem)
                      {
                         server_detail
                        ? history.push(
                            `/get_table_details/server/${table_detail?.PosTableMaster?.qr_code_id}`
                          )
                        : history.push(
                            `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
                          )
  
                      }
                      else{
                      changeModalStyle()
                      }
                    }
                  }
                  // color={"black"}
                  src={cross_icon}
                  height={"20"}
                />
              </div>
            </div> */}
            <div
              className={`item-details height-view${
                hideHeader === true ? "top-scroll" : ""
              }`}
            >
              <div className="p-3 bg-white Px-15">
                {/* {!hideHeader && ( */}
                <>
                  <div className={`position-relative pt-3`}>
                    <span
                      className="item-title d-block"
                      style={{ width: "90%" }}
                    >
                      {" "}
                      <span>{selected_item_detail?.menu_item_name} </span>
                      <span className="ml-3">{getItemTags()}</span>
                    </span>

                    {imageArr.length == 0 && (
                      <StyledSVG
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "0px",
                        }}
                        onClick={() => {
                          if (!showAddItem) {
                            server_detail
                              ? history.push(
                                  `/get_table_details/server/${table_detail?.PosTableMaster?.qr_code_id}`
                                )
                              : history.push(
                                  `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
                                );
                          } else {
                            changeModalStyle();
                          }
                        }}
                        // color={"black"}
                        src={close_black}
                        height={"20"}
                      />
                    )}
                  </div>
                </>
                {/* )} */}
                {selected_item_detail?.description ? (
                  <p className="description mt-2">
                    {selected_item_detail?.description}
                  </p>
                ) : null}
                <div className="mt-2 d-flex align-items-center">
                  <p
                    className={`pricing mt-0 item-promotion-price align-items-center ${
                      isPromotion ? "promotion-strike-off" : ""
                    }`}
                  >
                    {" "}
                    <small className="mr-1 fw-bolder">{`${
                      table_detail?.currency ?? ""
                    }`}</small>
                    <span>
                      {isPromotion
                        ? showPostTaxPrice
                          ? selected_item_detail?.promotions
                              ?.accutalPostTaxPrice
                            ? parseFloat(
                                selected_item_detail?.promotions
                                  ?.accutalPostTaxPrice
                              ).toFixed(2)
                            : "0.00"
                          : selected_item_detail?.promotions?.accutalPrice
                          ? parseFloat(
                              selected_item_detail?.promotions?.accutalPrice
                            ).toFixed(2)
                          : "0.00"
                        : showPostTaxPrice
                        ? selected_item_detail?.dinein_price?.DineInPostTax
                          ? parseFloat(
                              selected_item_detail?.dinein_price?.DineInPostTax
                            ).toFixed(2)
                          : "0.00"
                        : selected_item_detail?.dinein_price?.DineIn
                        ? parseFloat(
                            selected_item_detail?.dinein_price?.DineIn
                          ).toFixed(2)
                        : "0.00"}
                    </span>
                  </p>
                  {isPromotion && (
                    <p className="ml-2 mt-0 item-promotion-price hyperlink-color">
                      <small className="hyperlink-color">{`${
                        table_detail?.currency ?? ""
                      }`}</small>
                      <span>
                        {showPostTaxPrice
                          ? selected_item_detail?.dinein_price?.DineInPostTax
                            ? parseFloat(
                                selected_item_detail?.dinein_price
                                  ?.DineInPostTax
                              ).toFixed(2)
                            : "0.00"
                          : selected_item_detail?.dinein_price?.DineIn
                          ? parseFloat(
                              selected_item_detail?.dinein_price?.DineIn
                            ).toFixed(2)
                          : "0.00"}
                      </span>
                    </p>
                  )}
                  {
                    isPromotion?(
                      
                      <span className="discount d-flex align-items-center ml-2">
                        <StyledSVG
                          className="ml-2"
                          src={badge}
                          width={13}
                          color={
                            table_detail?.QrOrdering?.brand_highlight_color ||
                            "#8b8b8b"
                          }
                        />
                        
                        <p className="ml-2 my-0">
                          {Math.round((cal_discount() * 100).toFixed(2)) + "% Discount"}
                        </p>
                      </span>
                    ):null}
                    
                </div>

                {selected_item_detail?.allergy_tags &&
                selected_item_detail?.allergy_tags.length ? (
                  <div className="labels  d-flex align-items-center ml-0 mt-0">
                    {<StyledSVG src={tags} width={"13px"} className="mr-2" />}
                    {getItemFilterTags(selected_item_detail)}
                  </div>
                ) : (
                  <></>
                )}

                {isContainDiscountArr && getItemDiscountModifiers()}
              </div>
              {((selected_item_detail?.PosMenuitemsModifier &&
                selected_item_detail?.PosMenuitemsModifier.length > 0) ||
                (selected_item_detail?.StandardModifier &&
                  selected_item_detail?.StandardModifier.length > 0)) && (
                <div className="mt-3">
                  {getItemModifiers("")}
                  {getItemStandardModifiers()}
                </div>
              )}
              {checkExtraSpace() && <div className="extra-padding bg-white" />}

              {/* <div className="mt-3 bottom-margin">
                {selected_item_detail?.BundledOptionSeries?.length > 0 &&
                  !showModifiers &&
                  getBundleItems()}
                {selected_item_detail?.BundledOptionSeries?.length > 0 &&
                  !showModifiers &&
                  getModifierBundleItems()}
                {selected_item_detail?.StandardModifier !== undefined &&
                  selected_item_detail?.StandardModifier.length > 0 &&
                  !showModifiers && (
                    <Fragment>
                      <h5 className="sm-title bg-white"> Standard Modifiers</h5>
                      <div className="selectors">
                        {getItemStandardModifiers()}
                      </div>
                    </Fragment>
                  )}
                {selected_item_detail?.BundledOptionSeries?.length > 0 &&
                  showModifiers &&
                  getItemModifiers()}
              </div> */}
              {showNoteCon && (
                <>
                  <h5 className="sm-title bg-white mt-3 Px-15 tt-p ">Notes</h5>
                  <div className="message_col Px-15 bottom-margin">
                    <textarea
                      id={`comment_${selected_item_detail?.id}`}
                      name={`comment_${selected_item_detail?.id}`}
                      className={"form-control"}
                      placeholder="Add note here."
                      type="text"
                      onChange={(el) => {
                        setComment(el.target.value);
                      }}
                    >
                      {comment}
                    </textarea>
                  </div>
                </>
              )}
            </div>
          </section>

          <section className="bottom-sec-add-item py-3 center-btn default-bg">
            <div className="d-flex align-items-center default-bg px-2 Px-15">
              <div className="add-item-count shadow col-5 px-2 d-flex align-items-center justify-content-evenly bg-white">
                <button
                  className="remove-btn bg-white  align-items-center justify-content-center"
                  onClick={decreaseItem}
                >
                  <StyledSVG
                    color={
                      table_detail?.QrOrdering?.brand_highlight_color ||
                      "#8b8b8b"
                    }
                    src={minus}
                    width={"21px"}
                    height={"21px"}
                  />{" "}
                </button>
                <input
                  ref={qytEditInputRef}
                  type="number"
                  step={1}
                  max={999}
                  pattern="[0-9]*"
                  inputmode="numeric"
                  onBlur={() => {
                    if (isNaN(currentItemQyt) || currentItemQyt <= 0) {
                      editItemQyt(1);
                    }
                    setCurrentItemQytEdit(false);
                  }}
                  onChange={(e) => {
                    const qytVal = parseInt(
                      String(e.target.value ?? 1).slice(0, 3)
                    );
                    editItemQyt(qytVal);
                  }}
                  className="items-num bg-transparent"
                  value={currentItemQyt}
                  style={{ display: currentItemQytEdit ? "block" : "none" }}
                />
                <input
                  type="text"
                  className="items-num bg-transparent"
                  value={currentItemQyt}
                  onClick={() => {
                    setCurrentItemQytEdit(true);
                  }}
                  readOnly
                  style={{ display: currentItemQytEdit ? "none" : "block" }}
                />

                <button
                  className="add-btn d-flex   bg-white align-items-center justify-content-center"
                  onClick={increaseItem}
                >
                  <StyledSVG
                    color={
                      table_detail?.QrOrdering?.brand_highlight_color ||
                      "#8b8b8b"
                    }
                    src={plus}
                    width={"21px"}
                    height={"21px"}
                  />{" "}
                </button>
              </div>
              <div className="col-7 pl-2 pr-0">
                <CustomButton
                  onClick={() => {
                    if (
                      checkArray(pos_cart_detail?.payment_type) &&
                      pos_cart_detail?.payment_type.findIndex(
                        (p) => p.isPaid == true
                      ) > -1
                    ) {
                      showNotificationWithTimeout(
                        `Order has been split can not add items now`,
                        "error"
                      );
                    } else if (!isNaN(currentItemQyt)) AddToCartHandler();
                  }}
                  color={"white"}
                  title={"Add to Basket"}
                  className={"cart-button shadow  "}
                />
              </div>
            </div>
          </section>
        </div>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    }
  }

  return <>{getAddItemDetail()}</>;
}
const mapStateToProps = (state) => ({
  selected_item_detail: state.tableDetailReducer.selected_item_detail,
  edited_item_detail: state.tableDetailReducer.edited_item_detail,
  table_detail: state.tableDetailReducer.table_detail,
  filters: state.tableDetailReducer.filters,
  filter_tag_detail: state.tableDetailReducer.filter_tag_detail,
  cart_detail: state.cartDetailReducer.cart_detail,
  show_complete_page: state.cartDetailReducer.show_complete_page,
  pos_cart_detail: state.cartDetailReducer?.pos_cart_detail,
  loading: state.loaderReducer.loading,
  recent_order_detail: state.cartDetailReducer.recent_order_detail,
  show_session_expired_page: state.tableDetailReducer.show_session_expired_page,
  is_payment_cleared: state.paymentDetailReducer.is_payment_cleared,
  server_detail: state.tableDetailReducer.server_detail,
  server_user_detail: state.authDetailReducer.server_user_detail,
  
});

const mapDispatchToProps = {
  addItemCartDetail: addItemCartDetail,
  updateItemCartDetail: updateItemCartDetail,
  removeItemCartDetail: removeItemCartDetail,
  getTableDetailByFilter: getTableDetailByFilter,
  emptyEditedItemDetail: emptyEditedItemDetail,
  showNotificationWithTimeout: showNotificationWithTimeout,
};

AddItem = connect(mapStateToProps, mapDispatchToProps)(AddItem);
export default AddItem;

{
  /* <section className="add-items_sec custom_radio default-bg">
<div
  className={`bg-none item-details marginTop ${
    showBackWhite ? " wht-bg" : ""
  }${hideHeader === true ? " top-scroll" : ""}`}
>
  <div className="p-3 bg-white">
    <>
      <div className="d-flex align-items-center">
        <h5 className="item-title">
          {" "}
          {selected_item_detail?.menu_item_name}{" "}
        </h5>
        {getItemTags()}
      </div>

      <p className={`description mt-2`}>
        {selected_item_detail?.description}
      </p>
      <div className="mt-5 d-flex align-items-center">
        <p
          className={`pricing  item-promotion-price align-items-center ${
            isPromotion ? "promotion-strike-off" : ""
          }`}
        >
          {" "}
          <small>{`${table_detail?.currency ?? ""}`}</small>
          <span>
            {isPromotion
              ? showPostTaxPrice
                ? selected_item_detail?.promotions
                    ?.accutalPostTaxPrice
                  ? parseFloat(
                      selected_item_detail?.promotions
                        ?.accutalPostTaxPrice
                    ).toFixed(2)
                  : "0.00"
                : selected_item_detail?.promotions?.accutalPrice
                ? parseFloat(
                    selected_item_detail?.promotions?.accutalPrice
                  ).toFixed(2)
                : "0.00"
              : showPostTaxPrice
              ? selected_item_detail?.dinein_price?.DineInPostTax
                ? parseFloat(
                    selected_item_detail?.dinein_price
                      ?.DineInPostTax
                  ).toFixed(2)
                : "0.00"
              : selected_item_detail?.dinein_price?.DineIn
              ? parseFloat(
                  selected_item_detail?.dinein_price?.DineIn
                ).toFixed(2)
              : "0.00"}
          </span>
        </p>
        {isPromotion && (
          <p className="ml-2 item-promotion-price hyperlink-color align-items-center">
            <small className="hyperlink-color">{`${
              table_detail?.currency ?? ""
            }`}</small>
            <span>
              {showPostTaxPrice
                ? selected_item_detail?.dinein_price?.DineInPostTax
                  ? parseFloat(
                      selected_item_detail?.dinein_price
                        ?.DineInPostTax
                    ).toFixed(2)
                  : "0.00"
                : selected_item_detail?.dinein_price?.DineIn
                ? parseFloat(
                    selected_item_detail?.dinein_price?.DineIn
                  ).toFixed(2)
                : "0.00"}
            </span>
          </p>
        )}
        {isPromotion && (
          <span className="discount d-flex align-items-center ml-2">
            <StyledSVG
              color={
                table_detail?.QrOrdering?.brand_highlight_color ||
                "#8b8b8b"
              }
              className="ml-2"
              src={badge}
              width={13}
            />
            <p className="ml-2  my-0">
              {(cal_discount() * 100).toFixed(2) + "% Discount"}
            </p>
          </span>
        )}
      </div>
      {selected_item_detail?.allergy_tags &&
      selected_item_detail?.allergy_tags.length ? (
        <div className="labels d-flex align-items-center">
          {<StyledSVG src={tags} width={20} />}
          {getItemFilterTags(selected_item_detail)}
        </div>
      ) : (
        <></>
      )}
      {isContainDiscountArr && getItemDiscountModifiers()}
    </>

  </div>

  {((selected_item_detail?.PosMenuitemsModifier &&
    selected_item_detail?.PosMenuitemsModifier.length > 0) ||
    (selected_item_detail?.StandardModifier &&
      selected_item_detail?.StandardModifier.length > 0)) && (
    <div className="mt-3">
      {getItemModifiers("")}
      {getItemStandardModifiers()}
    </div>
  )}
  {showNoteCon && (
    <>
      <h5 className="sm-title bg-white mt-3">Notes</h5>
      <div className="message_col">
        <textarea
          id={`comment_${selected_item_detail?.id}`}
          name={`comment_${selected_item_detail?.id}`}
          className={"form-control"}
          placeholder="Add note here."
          type="text"
          onChange={(el) => {
            setComment(el.target.value);
          }}
        >
          {comment}
        </textarea>
      </div>
    </>
  )}
</div>
</section> */
}

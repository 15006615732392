import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoginLayout from "../Components/LoginLayout";
import LoginInput from "../Components/LoginInput";
import { useHistory } from "react-router-dom";
import {
    getComoCustomer,
  getXgateCustomer,
  showNotificationWithTimeout,
  verifyPassword,
} from "../../Redux/actions";
import { StyledSVG } from "../../Utils/style";
import { envelope, existingUser, green_loc_mark, newUser,lock } from "../../Assets";
import Modal from "../Components/Modal";
import { Terms } from "../Components/Terms";

const CheckPassword = ({ style, customerType, setCustomerType,email,userDetails,setRedirectrPassword }) => {
  var uuid = "not-valid";
 
  console.log(userDetails)
  const [password,setPassword]=useState("")
   
  let history = useHistory();
  const loaderReducer = useSelector((state) => state.loaderReducer);
  const tableReducer = useSelector((state) => state.tableDetailReducer);
  const merchantId = tableReducer?.table_detail?.Merchant?.id;

  const loyaltyPackage =
    tableReducer?.table_detail?.Merchant?.MerchantLoyaltySetting
      ?.LoyaltyPackage ?? null;

      const api_key=tableReducer?.table_detail?.Merchant?.MerchantLoyaltySetting?.api_key
      
  const tableNumber =
    tableReducer?.table_detail?.PosTableMaster?.table_number ?? "";
  const deviceId = uuid;
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authDetailReducer);
  const [showTermsPopup, setShowTermsPopup] = useState(false);

  const backBtnHandler = () => {
    console.log("setCustomerType", customerType);
    setRedirectrPassword(false)
  };

 

  useEffect(() => {
    // redirect / if not have table detail
    if (
      loaderReducer?.loading === false &&
      tableReducer?.table_detail?.show_table_not_found_modal === false &&
      (tableReducer?.table_detail?.Merchant?.id === "" ||
        tableReducer?.table_detail?.Merchant?.id === null ||
        tableReducer?.table_detail?.Merchant?.id === undefined ||
        tableReducer?.table_detail?.PosTableMaster?.qr_code_id === "" ||
        tableReducer?.table_detail?.PosTableMaster?.qr_code_id === null ||
        tableReducer?.table_detail?.PosTableMaster?.qr_code_id === undefined)
    ) {
      history.push("/404");
    }
    // if already login send to menu page
    if (
      tableReducer?.table_detail?.PosTableMaster?.qr_code_id !== undefined &&
      loaderReducer?.loading === false &&
      authReducer?.user_detail !== null &&
      authReducer?.user_detail !== undefined
    ) {
      history.push(
        `/get_table_details/${tableReducer?.table_detail?.PosTableMaster?.qr_code_id}`
      );
    }
  }, []);



  const submitHandler = (el) => {
    el.preventDefault();
    if(!password){
      showNotificationWithTimeout("Password is required","error")
      return;
    }
    if (loyaltyPackage?.code == "eber") {
      const payload = {
        merchant_id: merchantId ? merchantId : "",
        email: userDetails?.email ? userDetails?.email : "",
        phone: userDetails?.mobile ? userDetails?.mobile : "",
        external_member_id: userDetails?.memberID ? userDetails?.memberID : "",
        api_key,
        loyaltyType:tableReducer?.table_detail?.Merchant?.MerchantLoyaltySetting?.LoyaltyPackage?.code?
                      tableReducer?.table_detail?.Merchant?.MerchantLoyaltySetting?.LoyaltyPackage?.code:"",
        password
      };
      dispatch(verifyPassword(payload));
    }
    if (loyaltyPackage?.code == "como") {
      const payload = {
        merchant_id: merchantId ? merchantId : "",
        email: userDetails?.email ? userDetails?.email : "",
        phone: userDetails?.mobile ? userDetails?.mobile : "",
        external_member_id: userDetails?.memberID ? userDetails?.memberID : "",
        
      };

      dispatch(getComoCustomer(payload));

    }
    if (loyaltyPackage?.code == "xgate") {
      const xgateInputs = {
        merchantId: merchantId,
        tableNumber: tableNumber,
      };
      
        dispatch(getXgateCustomer(xgateInputs));
    }
   
 
  };




//   const resendSubmitHandler = (el) => {
//     dispatch(sendOtpDetail(email, "", merchantId, deviceId));
//   };

  const passwordChangeHandler = (el) => {
    setPassword(el.target.value)
  };

//   const otpChangeHandler = (el) => {
//     dispatch(updateOtpInput(el.target.value));
//   };
  return (
    <LoginLayout
      style={style}
      divClass="cart_sidebar"
      backHandler={backBtnHandler}
      showBackBtn={true}
    >
      <div className="login-form pt-4 px_15">
        <h3 className="merchant-title d-flex mt-2 justify-content-center">
          {tableReducer?.table_detail?.Merchant?.account_name}
        </h3>
        <form onSubmit={submitHandler} className="mx-5">
          <div className="group-sec">
            <div
              className="login-new d-flex align-items-center my-5"
              // style={{color:tableReducer?.table_detail?.QrOrdering?.brand_main_color}}
            >
              <StyledSVG src={existingUser}/>
              <span className="ml-3">Existing Customer</span> 
            </div>
           
          </div>
          <div className="title-heading d-flex align-items-center ">
          <StyledSVG src={lock} width="20" />
            <span className="mx-2 otp-text">Password{" "}</span>
          </div>
          <LoginInput
            placeholder={"Password"}
            type="password"
            value={password}
            required="true"
            onChange={passwordChangeHandler}
          />
          
          <div className="resend_btn_row my-4">
            
            <button
              type="submit"
              className="btn green-btn new-green"
              style={{
                backgroundColor:
                  tableReducer?.table_detail?.QrOrdering?.brand_main_color,
              }}
            >
              Submit
            </button>
          </div>
          <div className="col-12">
            <p className="my-1 text-center">
              By clicking <b>'Submit'</b>, I agree and accept to GoGMGo's{" "}
              <b onClick={() => setShowTermsPopup(true)}>Terms of Use</b> and{" "}
              <b
                onClick={() =>
                  window.open(
                    "https://www.gogmgo.com/privacy-policy/",
                    "_newtab"
                  )
                }
              >
                Privacy Policy
              </b>
            </p>
          </div>
        </form>
       
      </div>
      <Modal
        show={showTermsPopup}
        title={""}
        extraClass={"top50"}
        modalbody={<Terms />}
        OnSubmit={() => setShowTermsPopup(false)}
        submitBtnText="ok"
      />
    </LoginLayout>
  );
};

export default CheckPassword;

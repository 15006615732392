import React from "react";
import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { logger } from "redux-logger";
import rootReducer from "./Redux/reducers";
import rootSaga from "./Redux/sagas";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "font-awesome/css/font-awesome.min.css";


if (process.env.REACT_APP_TYPE === 'production') {
  // console.log("if")
  console.log = () => {}
  console.error = () => {}
  console.warn=()=>{}
  console.debug = () => {}
}



const sagaMiddleware = createSagaMiddleware();

/**
 * Add all the state in local storage
 * @param getState
 * @returns {function(*): function(*=)}
 */
const localStorageMiddleware = ({ getState }) => {
  return (next) => (action) => {
    const result = next(action);
    // localStorage.setItem("applicationState", JSON.stringify(getState()));
    try {
      const serializedState = JSON.stringify(getState());
      sessionStorage.setItem("state", serializedState);
    } catch (error) {
      // Ignore write errors.
    }
    return result;
  };
};

const reHydrateStore = () => {
  if (localStorage.getItem("applicationState") !== null) {
    // return JSON.parse(localStorage.getItem("applicationState")); // re-hydrate the store
  }
  try {
    const serializedState = sessionStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

const store = createStore(
  rootReducer,
  reHydrateStore(),
  // applyMiddleware(sagaMiddleware, localStorageMiddleware)
  applyMiddleware(sagaMiddleware, localStorageMiddleware)
);
sagaMiddleware.run(rootSaga);
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
// if (module.hot) {
//   module.hot.accept(App);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import QrReader from "react-qr-reader";
import { cameraIcon, imagePlaceholderIcon } from "../../../Assets";
import QrInput from "../../../Scenes/Components/QR_code/QrInput";
import { StyledSVG } from "../../../Utils/style";


const CustomCheckBox = ({
  data,
  chnageHandle,
  loginType,
  chnageInputHandle,
  handleQRScan,
  scannerModal,
  qrGalleryRef,
  setScannerModal,
  tableReducer,
  setShowFileInput,
  qrInputRef,
  scannerInvalidModal,
  scannerHandleSuccess,
  scannerHandleError,
}) => {

  // const { table_detail } = useSelector((state) => state.tableDetailReducer);
  // const passwordRequired=table_detail?.Merchant?.MerchantLoyaltySetting?.require_password_authentication


  return (
    <div className="checkbox-wrapper m-4">
      <div className="row col-12 align-items-center checkbox-align">
        {" "}
        <input
          type="radio"
          className="radio-btn"
          value={data?.value}
          onChange={chnageHandle}
          checked={loginType == data?.value}
        />
        {/* <img className="ml-3 radio-image" src={data?.icon} /> */}
       <span className=" mx-3 d-flex align-items-center">
       <StyledSVG src={data?.icon}/>
        <h5 className="ml-2 mb-0 input-title  ">{data?.label}</h5>
       </span>
      </div>
      <div
        className="mleft-5 mt-3"
        style={{
          display:
            loginType == data?.value && data?.value != "qr" ? "block" : "none",
        }}
      >
        <input
          name={data?.value}
          type="text"
          className="form-control input-radius"
          // required="true"
          onChange={chnageInputHandle}
        />
      </div>

      {loginType == data?.value && data?.value == "qr" && (
        <div
          className="mt-3 "

        >
          {data?.subLabel && (
            <div className="qr-sec">
              <div className="btn-sec col-12 d-flex px-0 pb-0">
                <a
                  href="javascript:void(0);"
                  className="green-btn btn-radius mr-2"
                  onClick={() => {
                    document.body.classList.add("modal-open");
                    setScannerModal(true);
                  }}
                  style={{
                    backgroundColor:
                      tableReducer?.table_detail?.QrOrdering?.brand_main_color,
                  }}
                >
                  <div className="cam">
                    <img src={cameraIcon} />
                    <br /> Use Camera{" "}
                  </div>
                </a>
                <a
                  href="javascript:void(0);"
                  className="green-btn btn-radius ml-2"
                  onClick={() => {
                    setShowFileInput(false);
                    if (qrInputRef.current) qrInputRef.current.click();
                  }}
                  style={{
                    backgroundColor:
                      tableReducer?.table_detail?.QrOrdering?.brand_main_color,
                  }}
                >
                  <div className="d-none">
                    {!scannerInvalidModal && (
                      <QrInput
                        handleScan={scannerHandleSuccess}
                        ref={qrInputRef}
                        onError={scannerHandleError}
                      />
                    )}
                  </div>
                  <div className="img">
                    <img src={imagePlaceholderIcon} />
                    <br /> Use Image
                  </div>
                </a>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomCheckBox;
